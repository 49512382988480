import React from "react"
import {
  graphql,
  Link,
  useStaticQuery
} from "gatsby"
import {
  AiFillAppstore
} from "react-icons/ai"

import Layout from "../components/Layout"
import Head from "../components/Head"
import AppCardView from "../components/AppCardView"
import "bootstrap/dist/css/bootstrap.min.css"
import appStyles from "./apps.module.scss"
// import { Button } from "react-;"

const AppHomePage = () => {
    const data = useStaticQuery(graphql `
    query {
      appInfo: allContentfulAppDetail(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            appTitle
            googlePlayStoreLink
            slug
            #======
            appIcon {
              id
              file {
                url
              }
              title
              contentful_id
              fluid {
                src
              }
            }
            # ======
            screenShots {
              id
              title
              file {
                url
                details {
                  size
                  image {
                    width
                    height
                  }
                }
              }

              fluid {
                src
                sizes
              }
            }

            #=========
            apkFile {
              title
              description
              file {
                details {
                  size
                }
              }
            }
            #=========
            excerpt {
              json
            }
            publishedDate(formatString: "MMM Do, YYYY")
            updatedDate(formatString: "MMM Do, YYYY")
            apkFileRelativePath
          }
        }
      }
      # TODO: please also include ios apps extension to be include in the filter
      apkFile: allFile(filter: { extension: { eq: "apk" } }) {
        edges {
          node {
            size
            relativePath
          }
        }
      }
    }
  `)
    // console.log(JSON.stringify(data, null, 4))
    const filterdData = data.appInfo.edges.filter(post => {
      // console.log({ apptitle: post.node.appTitle })
      return (
        post.node.appTitle === "telebirr Partner" ||
        post.node.appTitle === "telebirr SuperApp"
        // post.node.appTitle === "telebirr SuperApp" ||
        // post.node.appTitle === "My Ethiotel Mobile App"
      )
    })
    // console.log(filterdData)

    // return null
    return ( <Layout>
      <div className = {
        appStyles.cardContainer
      } >
      <Head title = "Home" />
      <h3 style = {
        {
          textAlign: "center",
          color: "#018FD3",
          fontWeight: 700
        }
      } >
      Welcome to Ethio telecom Mobile App Store </h3> <h3 style = { {
      textAlign: "center",
      color: "#018FD3",
      fontWeight: 500
    }
  } >
  Our Latest Mobile App </h3> <ol className = {
appStyles.posts
} > {
  filterdData.map(post => {
    return ( < li className = {
        appStyles.post
      }
      key = {
        post.node.slug
      } >
      <AppCardView post = {
        post
      }
      /> </li >
    )
  })
} </ol> <div style = { {
textAlign: "center",
  marginTop: "20px",
  marginBottom: "40px"
}
} > {
  /* <Link to="/" onClick="window.location.href=this"> */
} <Link to = "/apps"
  // onClick="window.location.href=this"
  >
  Explore more Ethio telecom Mobile Apps <AiFillAppstore/>
  </Link> </div > </div> </Layout> )

// return (
//   <Layout>
//     <div className={appStyles.cardContainer}>
//       <Head title="Apps" />
//       <h2 style={{ textAlign: "center", color: "#018FD3" }}>
//         Ethio Telecom Mobile Apps
//       </h2>
//       <ol className={appStyles.posts}>
//         {filterdData.appInfo.edges.map(post => {
//           return (
//             <li className={appStyles.post} key={post.node.slug}>
//               <AppCardView post={post} />
//             </li>
//           )
//         })}
//       </ol>
//     </div>
//   </Layout>
// )
}

export default AppHomePage