// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import { Link } from "gatsby"
import React from "react"
import { Card } from "react-bootstrap" 
import { FaDownload } from "react-icons/fa"
// import { IoLogoGooglePlaystore } from "react-icons/io5"
// import styled from "styled-components"
import { CgDetailsMore } from "react-icons/cg"
import CardStyles from "./AppCardView.module.scss"

import playstoreImg from "../images/google-play-store-logo-svgrepo-com.svg"
// import appStoreImage from "../images/ethio app store.jpg"

// const EtButton = styled(Button)`
//   background: #8bc53f;
//   border: none;
//   margin: 0;
//   padding: 2px;

//   &:hover {
//     background: #0072bc;
//     border: none;
//   }
// `
// const CLink = Card.Link
// const StyledLink = styled(CLink)`
//   background: #8bc53f;
//   border: none;
//   margin: 0;
//   textalign: center;
//   &:hover {
//     background: #0072bc;
//     border: none;
//   }
// `

const AppCardVidew = ({ post }) => {

  const hasPlayStoreLink =post?.node?.googlePlayStoreLink !==null
  
  return (
    <div>
      <Card className={CardStyles.cardContainer + " h-100 card-deck"}>
        <Card.Header
          style={{
            textAlign: "center",
            background: "transparent"
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Card.Img
              variant="top"
              
              src={post?.node?.appIcon?.fluid?.src}
              className="d-block mx-auto img-fluid w-25" // width 25%              
              alt={post.node.appIcon.title}
            />
          </div>
        </Card.Header>
        <br />
        <Card.Body>
          <Card.Title
            style={{ color: "#008dd2", fontSize: "1.15rem", fontWeight: 500 }}
          >
            {post.node.appTitle}
          </Card.Title>
          {/* /apks/telebirr-v3.apk */}
          <Card.Text>
          <div style={{whiteSpace: "nowrap" }}> Download from</div>
            <div style={{display:"flex",alignItems:"center",gap:"2px",justifyContent:"center",width:"full"}} className="">
              {/* <span style={{whiteSpace: "nowrap" }}> Download from</span> */}
            <a           
              className={CardStyles.appDownloadLink} 
              href={
                // hasPlayStoreLink || !post?.node?.apkFileRelativePath?.includes("telebirr_customer_app_v4") || !post?.node?.apkFileRelativePath?.includes("telebirr_partner_v39.apk")
                hasPlayStoreLink
                  ? post.node.googlePlayStoreLink
                  : post.node.apkFileRelativePath
              }
              style={{ background: "transparent" }}
              target={post.node.googlePlayStoreLink !== null ? "__blank" : ""}
            >
             
              {hasPlayStoreLink ? (
                <div style={{display:"flex",flexDirection:"column",gap:"2px",alignItems:"center",justifyContent:"center"}}>
                <img
                  alt="download"
                  src={playstoreImg}
                  width="20px"
                  height="20px"
                />
                <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Play Store</span>
                </div>
              ) : (
                <div style={{display:"flex",flexDirection:"column",gap:"2px",alignItems:"center",justifyContent:"center"}}>
                <FaDownload style={{  width:"20px",
                height:"20px"}} />
                
                <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Ethio App Store</span>
                </div>
              )}
              
            </a>
            {/* {(post.node?.googlePlayStoreLink && post.node?.apkFileRelativePath && post.node?.apkFileRelativePath.includes("telebirr_Partner_1.1.0.033") ) &&  <span>or</span> }
            {(post.node?.googlePlayStoreLink && post.node?.apkFileRelativePath && post.node?.apkFileRelativePath.includes("telebirr_Partner_1.1.0.033") )&&   */}
            {(post.node?.googlePlayStoreLink && post.node?.apkFileRelativePath && post.node?.apkFileRelativePath.includes("telebirr_customer_app_v5.apk") ) &&  <span>or</span> }
            {(post.node?.googlePlayStoreLink && post.node?.apkFileRelativePath && post.node?.apkFileRelativePath.includes("telebirr_customer_app_v5.apk") )&&  
            <a           
              className={CardStyles.appDownloadLink} 
              href={post.node.apkFileRelativePath}
              style={{ background: "transparent" }}
              target={post.node?.apkFileRelativePath !== null ? "__blank" : ""}
            >
               
           <div style={{display:"flex",alignItems:"center",gap:"4px"}}>        
           <div style={{display:"flex",flexDirection:"column",gap:"2px",alignItems:"center",justifyContent:"center"}}>
                <FaDownload style={{  width:"20px",
                height:"20px"}} />
                
                <span style={{fontSize:"10px",fontStyle:"normal",fontWeight:"100", whiteSpace: "nowrap" }}>Ethio App Store</span>
                </div>
               </div>          
              
            </a>}
            </div>
           
            <span>
              Size:{" "}
              {post?.node?.apkFile?.file &&
                (
                  parseFloat(post?.node?.apkFile?.file?.details?.size) /
                  (1024 * 1024)
                ).toFixed(1)}
              {" MB"}
            </span>
            <br />
            <div>
              <p style={{ fontSize: "0.75rem" }}>
                <span>
                  Published: <strong>{post.node.publishedDate}</strong>
                </span>
                <br />
                <span>
                  Updated: <strong>{post.node.updatedDate}</strong>
                </span>
              </p>
            </div>
          </Card.Text>
          <Card.Link
            href={`/apps/${post.node.slug}`}
            // href={`/${post.node.slug}`}
            className={CardStyles.cardLink}
          >
            Details <CgDetailsMore />
          </Card.Link>
        </Card.Body>
      </Card>
    </div>
  )
}

export default AppCardVidew
